<template>
	<div class="group-detail">
		<w-navTab titleText="队伍详情"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="nav-picture">
			<img v-if="group.department_image == ''" src="@/assets/img/test255.png" alt="" />
			<img v-else :src="group.department_image" alt="" />
		</div>
		<div class="head">
			<div class="head-top">
				<div class="head-nav">
					<div class="head-nav-picture"><img src="@/assets/img/test203.png" alt="" /></div>
					<div class="head-nav-right">
						<div class="right-title">{{ group.department_title }}</div>
						<div class="right-text">平安义警注册总人数：{{ userTotal }}人 </div>
					</div>
				</div>
				<div :class="['head-conner', { conner: !conner }]">{{ group.intro }}</div>
				<div class="head-btn" @click="conner = !conner">
					<span>{{ conner == false ? '展开信息' : '收起信息' }}</span>
				</div>
				<div class="head-picture">
					<img v-if="!conner" src="@/assets/img/icon211.png" alt="" />
					<img v-else src="@/assets/img/icon212.png" alt="" />
				</div>
			</div>

			<div class="middle">
				<div class="middle-top">
					<span></span>
					基本信息
				</div>
				<div class="middle-item">
					<div class="middle-item-title">平安志愿者注册人数：</div>
					<div class="middle-item-text">{{ group.total_zyz }}人</div>
				</div>
				<div class="middle-item">
					<div class="middle-item-title">义警注册人数：</div>
					<div class="middle-item-text">{{ group.total_yj }}人</div>
				</div>
				<div class="middle-item">
					<div class="middle-item-title">主要负责人：</div>
					<div class="middle-item-text">{{ group.manager }}</div>
				</div>
				<div class="middle-item">
					<div :class="['middle-item-title',{'title-active': type != 1}]">{{type == 1 ? '协会电话' : '电话'}}：</div>
					<div class="middle-item-text">{{ group.telephone }}</div>
				</div>
				<div class="middle-item">
					<div :class="['middle-item-title',{'title-active': type != 1}]">{{type == 1 ? '协会地址' : '地址'}}：</div>
					<div class="middle-item-text">{{ group.address }}</div>
				</div>
			</div>
			
			<div class="footer">
				<div class="footer-top">
					<span></span>
					管理架构
				</div>
				<div class="footer-item" v-for="(item, key) in groupList">
					<div class="footer-item-title" @click="onClick(key)">
						<div class="footer-item-left">
							<div class="item-picture"><img :src="item.image" alt="" /></div>
							<div :class="['item-title', { 'item-title-active': item.isShow == true }]">{{ item.title }}
							</div>
						</div>
						<div class="footer-item-text">
							{{ item.total }}人
							<van-icon name="arrow" v-if="item.isShow == false" />
							<van-icon name="arrow-down" v-else />
						</div>
					</div>
					<div class="footer-item-body" v-show="item.isShow">
						<div class="middle-bottom-item" v-for="vo in item.children"
							@click="$router.push({ name: 'GroupChatUser', params: { id: vo.id, district_id: district_id } })">
							<div class="item-left">{{ vo.title }}</div>
							<div class="item-right">
								{{ vo.total ? vo.total : 0 }}人
								<van-icon name="arrow" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Group from '@/api/group';
	import DistrictHelper from '@/vendor/district';
	import LoadingPage from '@/components/LoadingPage';

	export default {
		name: 'GroupDetail',
		data() {
			return {
				conner: false,
				group: '',
				userTotal: 0,
				groupList: [],
				district_id: '',
				type: 1,
				loadingPage: true,
			};
		},
		created() {
			if (this.$route.params.district_id) {
				this.district_id = this.$route.params.district_id
			} else {
				this.district_id = DistrictHelper.getDistrictId();
			}
			let params = {
				department_id: this.$route.params.id,
				district_id: this.district_id
			}
			Group.chatGroup(params)
				.then(result => {
					let groupList = result.data.chat_groups;
					result.data.chat_groups.forEach((item, key) => {
						item.isShow = false;
					});
					
					this.groupList = result.data.chat_groups;
					this.group = result.data;
					this.userTotal = this.group.total_zyz + this.group.total_yj;
					if (this.group.department_title.includes('协会')) {
						this.type = 1
					} else {
						this.type = 2
					}
					this.loadingPage = false
				})
				.catch(error => {
					console.log(error);
				});
		},
		methods: {
			onClick(key) {
				if (this.groupList[key].children == '') {
					this.$router.push({
						name: 'GroupChatUser',
						params: {
							id: this.groupList[key].id,
							district_id: this.district_id
						}
					})
				}
				this.groupList[key].isShow = !this.groupList[key].isShow;
			}
		},
		components: {
			LoadingPage
		}
	};
</script>

<style lang="less" scoped>
	.group-detail {
		.nav-picture {
			width: 100%;
			height: 220px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.head {
			width: 100%;
			margin-top: -15px;
			position: relative;
			z-index: 2;
			padding-bottom: 10px;
			box-sizing: border-box;
			background: #f2f2f2;
			border-radius: 15px 15px 0px 0px;

			.head-top {
				background: #fff;
				padding: 0 10px;
				padding-bottom: 15px;
				border-radius: 15px 15px 0px 0px;

				.head-nav {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					position: relative;
					top: -10px;
					margin-left: 10px;

					.head-nav-picture {
						width: 80px;
						height: 80px;

						img {
							width: 100%;
							height: 100%;
							border-radius: 50%;
							object-fit: cover;
						}
					}

					.head-nav-right {
						margin-top: 5px;
						margin-left: 5px;

						.right-title {
							font-weight: bold;
							font-size: 20px;
							line-height: 29px;
							color: #3377ff;
						}

						.right-text {
							font-size: 14px;
							line-height: 16px;
							color: #666;
							margin-top: 5px;
						}
					}
				}

				.head-conner {
					font-size: 16px;
					line-height: 24px;
					color: #333;
				}

				.conner {
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 3;
					overflow: hidden;
				}

				.head-btn {
					text-align: center;
					margin-top: 5px;

					span {
						font-size: 14px;
						line-height: 22px;
						color: #666;
						padding: 0 5px;
						border: 1px solid #aaaaaa;
						border-radius: 4px;
					}
				}

				.head-picture {
					width: 16px;
					height: 14px;
					margin-top: 5px;
					margin-left: 50%;
					transform: translateX(-50%);

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}

			.middle {
				padding: 10px;
				margin: 0 10px;
				background: #ffffff;
				box-shadow: 0px 0px 8px rgba(51, 119, 255, 0.1);
				border-radius: 6px;
				margin-top: 15px;
				box-sizing: border-box;
				margin-bottom: 10px;

				.middle-top {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					font-size: 18px;
					line-height: 26px;
					color: #3377ff;
					padding-bottom: 10px;
					border-bottom: 0.5px solid #ddd;

					span {
						width: 2px;
						height: 20px;
						background: #3377ff;
						margin-right: 5px;
					}
				}

				.middle-item {
					display: flex;
					margin-top: 10px;

					.middle-item-title {
						min-width: 90px;
						font-size: 16px;
						line-height: 24px;
						color: #333;
					}

					.title-active {
						width: 55px;
						min-width: 55px !important;
						font-size: 16px;
						line-height: 24px;
						color: #333;
					}

					.middle-item-text {
						font-size: 16px;
						line-height: 24px;
						color: #666;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						overflow: hidden;
					}
				}
			}

			.footer {
				padding: 10px;
				background: #ffffff;
				box-shadow: 0px 0px 8px rgba(51, 119, 255, 0.1);
				border-radius: 6px;
				margin: 0 10px;
				box-sizing: border-box;

				.footer-top {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					font-size: 18px;
					line-height: 26px;
					color: #3377ff;
					padding-bottom: 10px;
					border-bottom: 0.5px solid #ddd;

					span {
						width: 2px;
						height: 20px;
						background: #3377ff;
						margin-right: 5px;
					}
				}

				.footer-item {
					.footer-item-title:last-child {
						border: 0;
					}

					.footer-item-title {
						display: flex;
						justify-content: space-between;
						align-items: center;
						margin-top: 10px;
						padding-bottom: 10px;
						border-bottom: 0.5px dashed #ddd;

						.footer-item-left {
							display: flex;
							justify-content: flex-start;
							align-items: center;

							.item-picture {
								width: 40px;
								min-width: 40px;
								height: 40px;
								margin-right: 10px;

								img {
									width: 100%;
									height: 100%;
									border-radius: 50%;
									object-fit: cover;
								}
							}

							.item-title {
								width: 200px;
								max-width: 200px;
								flex-wrap: wrap;
								font-size: 18px;
								line-height: 20px;
								color: #333;
							}

							.item-title-active {
								font-weight: bold;
								color: #3377ff;
							}
						}

						.footer-item-text {

							font-size: 16px;
							line-height: 20px;
							color: #666;
						}
					}

					.footer-item-body {
						background-color: #fff;
						border-radius: 0 0 6px 6px;

						.middle-bottom-item {
							display: flex;
							justify-content: space-between;
							align-items: center;
							padding: 8px 0;
							border-bottom: 0.5px dashed #ddd;

							.item-left {
								font-weight: 400;
								font-size: 16px;
								line-height: 23px;
								color: #333;
							}

							.item-right {
								font-size: 14px;
								line-height: 20px;
								color: #666;
							}
						}

						.middle-bottom-item:last-child {
							border: 0;
						}
					}
				}
			}
		}
	}
</style>